<template>
  <div>
    <div class="">
      <div class="container w-full mx-auto py-10 px-4 md:px-0">
        <ViewTitle :title="$t('top.title')" />
        <ViewSubtitle :subtitle="$t('top.subtitle')" />

        <button
          class="
            lg:hidden
            flex
            items-center
            px-3
            py-2
            my-4
            rounded-2xl
            bg-gray-200
            dark:bg-webmoney dark:hover:bg-blue-400
            hover:bg-gray-300
          "
          @click="showOrHideSidebar"
        >
          <FilterIcon class="w-4 h-4 relative top-0.5 mr-1" />
          {{ $t("top.sidebar.filters") }}
        </button>

        <Sort />

        <div class="flex w-full">
          <div class="lg:mr-4 w-full">
            <SiteItems :sites="sites" :loading="loading" />
            <div class="text-right">
              <button
                v-if="showAddPageButton && !loading"
                type="button"
                @click="nextPage"
                class="
                  inline-block
                  bg-webmoney
                  text-white
                  px-4
                  py-1
                  rounded
                  hover:bg-blue-400 hover:text-white
                  shadow
                "
              >
                {{ $t("top.more_sites") }}
              </button>
            </div>
          </div>

          <Sidebar />
          <div
            @click="showOrHideSidebar"
            v-if="showSidebar"
            class="overflow fixed z-30 top-0 bottom-0"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { FilterIcon } from "vue-feather-icons";
import SiteItems from "@/components/SiteItems.vue";
import Sidebar from "@/components/TopSidebar.vue";
import Sort from "@/components/TopSort.vue";
import ViewTitle from "@/components/ViewTitle.vue";
import ViewSubtitle from "@/components/ViewSubtitle.vue";
import qs from "qs";

export default {
  components: {
    FilterIcon,
    SiteItems,
    Sidebar,
    Sort,
    ViewTitle,
    ViewSubtitle,
  },
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  computed: mapState({
    sites: (state) => state.top.list,
    loading: (state) => state.top.loading,
    filters: (state) => state.top.filters,
    showSidebar: (state) => state.top.showSidebar,
    showAddPageButton: (state) => state.top.showAddPageButton,
    clickAddPageButton: (state) => state.top.clickAddPageButton,
  }),
  created() {
    const routerParams = this.$route.params;
    const params = {};
    if (routerParams.sortField) params.sortField = routerParams.sortField;
    if (routerParams.sortDirection)
      params.sortDirection = routerParams.sortDirection;
    if (routerParams.page) params.page = +routerParams.page || undefined;

    if (params.page && this.$route.query.filters) {
      let shortFilters = qs.parse(this.$route.query.filters);
      this.$store.dispatch(
        "top/setFilters",
        this.filtersInQueryToState(shortFilters)
      );
    }

    this.$store.dispatch("top/setState", params);
    this.$store.dispatch("top/getSitesTop");
  },
  beforeDestroy() {
    this.$store.dispatch("top/clearState");
  },
  watch: {
    $route: ["queryChanged"],
  },
  methods: {
    queryChanged() {
      const routerParams = this.$route.params;
      if (!routerParams.sortField && !routerParams.sortDirection) {
        this.$store.dispatch("top/clearState");
      }

      this.$store.dispatch("top/getSitesTop");
    },
    nextPage() {
      this.$store.dispatch("top/addPage");
    },
    openSite(domain) {
      this.$router.push({ name: "site", params: { domain: domain } });
    },
    filtersInQueryToState(obj) {
      let filtersObj = this.filters;
      let keysArray = Object.keys(obj);
      keysArray.forEach((key) => {
        if ("from" in obj[key]) {
          filtersObj[key].from = +obj[key].from;
        }
        if ("to" in obj[key]) {
          filtersObj[key].to = +obj[key].to;
        }
        if (!("from" in obj[key]) && !("to" in obj[key])) {
          filtersObj[key] = obj[key];
        }
      });
      return filtersObj;
    },
    showOrHideSidebar() {
      this.$store.dispatch("top/showOrHideSidebar");
    },
  },
};
</script>
