<template>
  <span class="mr-3 flex items-center">
    <button
      @click="changeSort(name)"
      class="
        text-webmoney
        dark:text-blue-400
        hover:text-blue-400
        dark:hover:text-blue-300
        mr-1
      "
    >
      {{ $t(`top.sort.${title}`) }}
    </button>
    <arrow-up-icon
      v-if="sortDirection == 'asc' && sortField == name"
      size="1x"
      class="text-gray-500 dark:text-gray-400"
    ></arrow-up-icon>
    <arrow-down-icon
      v-if="sortDirection == 'desc' && sortField == name"
      size="1x"
      class="text-gray-500 dark:text-gray-400"
    ></arrow-down-icon>
  </span>
</template>

<script>
import { ArrowDownIcon, ArrowUpIcon } from "vue-feather-icons";
import { mapState } from "vuex";
export default {
  components: {
    ArrowDownIcon,
    ArrowUpIcon,
  },
  props: ["name", "title"],
  computed: mapState({
    sortDirection: (state) => state.top.sortDirection,
    sortField: (state) => state.top.sortField,
  }),
  methods: {
    changeSort(sortField) {
      this.$store.dispatch("top/setSort", sortField);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>