<template>
  <div class="mb-5 md:flex items-center">
    <span class="mr-3 dark:text-gray-300">{{ $t("top.sort.title") }}:</span>
    <TopSortItem
      v-for="(item, index) in sortItems"
      :key="index"
      :name="item.name"
      :title="item.title"
    />
  </div>
</template>

<script>
import TopSortItem from "@/components/TopSortItem.vue";
export default {
  components: { TopSortItem },
  data() {
    return {
      sortItems: [
        {
          title: "by_advisor_rank",
          name: "advisor",
        },
        {
          title: "by_webmoney_rank",
          name: "webmoney",
        },
        {
          title: "by_alexa_rank",
          name: "alexa",
        },
        {
          title: "by_users_rating",
          name: "users",
        },
        {
          title: "by_feedbacks_count",
          name: "feedbacks",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>