<template>
  <div class="flex flex-wrap">
    <div class="flex justify-between items-center w-full mb-2">
      <p class="w-full">
        {{ title }}
      </p>
      <button type="button" @click="clearFilter" v-if="showInputsClearButton">
        <XIcon class="w-5 h-5 hover:text-gray-400 cursor-pointer" />
      </button>
    </div>
    <div class="flex w-full justify-between">
      <input
        type="number"
        :min="min"
        :max="max - step"
        :placeholder="$t('top.sidebar.from')"
        :step="step"
        :name="name + '-min'"
        :title="name"
        class="
          px-2
          py-1
          mr-4
          flex-grow
          border
          rounded
          overflow-hidden
          dark:bg-gray-700 dark:border-gray-700
          focus:outline-none focus:ring-2
          ring-webmoney
          dark:ring-gray-500
        "
        v-model.number="from"
      />
      <input
        type="number"
        :min="min + 1"
        :max="max"
        :placeholder="$t('top.sidebar.to')"
        :step="step"
        :name="name + '-max'"
        :title="name"
        class="
          px-2
          py-1
          flex-grow
          border
          rounded
          overflow-hidden
          dark:bg-gray-700 dark:border-gray-700
          focus:outline-none focus:ring-2
          ring-webmoney
          dark:ring-gray-500
        "
        v-model.number="to"
      />
    </div>
  </div>
</template>

<script>
import { XIcon } from "vue-feather-icons";

export default {
  components: {
    XIcon,
  },
  props: ["title", "min", "max", "step", "name"],
  mounted() {
    this.showInputsClearButton =
      typeof this.from == "number" || typeof this.to == "number";
  },
  updated() {
    this.showInputsClearButton =
      typeof this.from == "number" || typeof this.to == "number";
  },
  data() {
    return {
      showInputsClearButton: false,
    };
  },
  computed: {
    from: {
      get() {
        return this.$store.state.top.filters[this.name].from;
      },
      set(val) {
        this.showInputsClearButton =
          typeof val == "number" || typeof this.to == "number";
        this.$store.dispatch("top/getRank", {
          name: this.name,
          value: val,
          way: "from",
        });
      },
    },
    to: {
      get() {
        return this.$store.state.top.filters[this.name].to;
      },
      set(val) {
        this.showInputsClearButton =
          typeof val == "number" || typeof this.from == "number";
        this.$store.dispatch("top/getRank", {
          name: this.name,
          value: val,
          way: "to",
        });
      },
    },
  },
  methods: {
    clearFilter() {
      this.from = null;
      this.to = null;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>