<template>
  <form
    class="
      lg:flex
      flex-col flex-none
      self-start
      w-72
      border
      dark:border-gray-700
      lg:rounded-lg
      rounded-l-lg
      ml-auto
      lg:mt-3
      mt-0
      lg:pt-0
      pt-20
      lg:relative
      fixed
      z-40
      top-0
      bottom-0
      right-0
      lg:overflow-y-hidden
      overflow-y-scroll
      bg-white
      dark:bg-gray-800
    "
    :class="[showSidebar ? 'flex' : 'hidden']"
  >
    <button
      type="button"
      href="#"
      class="m-4 mb-0 block ml-auto lg:hidden absolute right-0 top-16"
      @click="showOrHideSidebar"
    >
      <XIcon class="w-10 h-10" />
    </button>

    <div class="flex flex-col space-y-4 p-4">
      <p class="font-bold text-webmoney dark:text-white">
        {{ $t("top.sidebar.site_rating") }}
      </p>
      <Rank
        :title="$t('top.sidebar.advisor_rank')"
        :min="0"
        :max="10"
        :step="1"
        name="advisor"
      />
      <Rank
        :title="$t('top.sidebar.webmoney_rank')"
        :min="0"
        :max="10"
        :step="1"
        name="webmoney"
      />
      <Rank
        :title="$t('top.sidebar.alexa_rank')"
        :min="0"
        :max="10"
        :step="1"
        name="alexa"
      />
      <Rank
        :title="$t('top.sidebar.users_rank')"
        :min="0"
        :max="10"
        :step="0.1"
        name="users"
      />
      <Rank
        :title="$t('top.sidebar.feedbacks_count')"
        :min="0"
        :max="10"
        :step="1"
        name="feedbacks"
      />
      <Rank
        :title="$t('top.sidebar.subscriptions_count')"
        :min="0"
        :max="10"
        :step="1"
        name="subscriptions"
      />
      <Rank
        :title="$t('top.sidebar.visits')"
        :min="0"
        :max="10"
        :step="1"
        name="visits"
      />
      <Rank
        :title="$t('top.sidebar.uniq_visits')"
        :min="0"
        :max="10"
        :step="1"
        name="uniq_visits"
      />
    </div>

    <div class="flex flex-col space-y-4 border-t dark:border-gray-700 p-4">
      <span class="font-bold text-webmoney dark:text-white">
        {{ $t("top.sidebar.site_status") }}
      </span>
      <label for="status_system_member">
        <input
          v-model.number="status"
          type="checkbox"
          value="system"
          title="status"
          class="mr-1"
          id="status_system_member"
        />
        {{ $t("top.sidebar.status_system") }}
      </label>

      <label for="status_webmoney_member">
        <input
          v-model.number="status"
          type="checkbox"
          value="webmoney"
          title="status"
          class="mr-1"
          id="status_webmoney_member"
        />
        {{ $t("top.sidebar.status_webmoney") }}
      </label>
      <label for="status_danger_site">
        <input
          v-model.number="status"
          type="checkbox"
          value="danger"
          title="status"
          class="mr-1"
          id="status_danger_site"
        />
        {{ $t("top.sidebar.status_danger") }}
      </label>
    </div>
  </form>
</template>
<script>
import { XIcon } from "vue-feather-icons";
import Rank from "./TopSidebarRank.vue";
import { mapState } from "vuex";

export default {
  components: {
    XIcon,
    Rank,
  },
  mounted() {},
  computed: {
    ...mapState({
      filters: (state) => state.top.filters,
      showSidebar: (state) => state.top.showSidebar,
    }),
    status: {
      get() {
        return this.$store.state.top.filters.status;
      },
      set(val) {
        this.$store.dispatch("top/getStatus", {
          status: val,
        });
      },
    },
  },
  methods: {
    showOrHideSidebar() {
      this.$store.dispatch("top/showOrHideSidebar");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>